.root {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10%;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .content {
    padding: 0 2.5%;
  }
}
