.header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 10%;
}

.anashimIL {
  width: 151px;
  display: block;
  object-fit: contain;
  height: 100%;
}
.navbarDesktopView{
  display: block;
}
.navbarMobileView{
  display: none;
}
@media (max-width: 768px) {
  .header {
    padding: 10% 2.5% 2.5% 2.5%;
  }
  .anashimIL {
    width: 88px;
    display: block;
    object-fit: contain;
    height: 100%;
  }
  .anashimInnovationHub{
  width:219px;
   height:36px;
  }
  .navbarDesktopView{
    display: none;
  }
  .navbarMobileView{
  display: block;
}
}
