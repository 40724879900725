.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.5%;
}
@media (max-width: 768px) {
  .footer {
    padding: 0 2.5% 4rem 2.5%;
    justify-content: unset;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .footer > img {
    width: 100%;
  }
  .appleseeds{
    width:68px;
    height: 27px;
  }
  
.innovationAuthority{
    width:124px;
    height: 47px;
  }
.ministryOfEconomy{
    width:73px;
    height: 39px;
  }
.nationalDigitalAgency{
    width:112px;
    height: 36px;
  }

}
  
