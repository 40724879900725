.navbar{
  display: block;
}
.list{
  display: flex;
  list-style: none;
  column-gap: 3.5rem;
}

.link{
  font-size: 2.4rem;
  font-weight: 600;
}
.linkContainerWithIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}

@media (max-width:768px){
  .navbar{
    position: absolute;
    top:var(--header-height, 0);
    height: calc(100vh - var(--header-height, 0) - 5px);
    width: 100vw;
    left: 0;
    background-color: #fff;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .list{
    flex-direction: column;
      column-gap: 0;
      row-gap: 3.5rem;
  }
}