.hamburgerMenu {
  display: inline-block;
  cursor: pointer;
  width: 38px;
}

.line {
  width: 30px; 
  height: 3px; 
  background-color: var(--charcoal-gray); 
  margin: 5px 0; 
  transition: all 0.3s ease-in-out; 
}

.xIcon {
  position: relative;
  width: 28px;
  height: 28px;
  color: var(--charcoal-gray);
  display: inline-block;
}

.xIcon::before, .xIcon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%; 
  height: 2px; 
  background-color: var(--charcoal-gray);
}

.xIcon::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.xIcon::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
